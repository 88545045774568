<template>
    <div class="main_business_div">
        <div>
            <div class="business_banner">
                <img :src="imgSrc[0]"/>
            </div>
            <div class="business_title_div allContentWidth">
                <div class="tab_div">
                    <ul class="tab-title">
                        <li v-for="item in tabArr" :key="item.id" :class="{active:curActive==item.id}" @click="tabClickFun(item.id)">{{item.name}}</li>
                    </ul>
                </div>
                <div class="business_title_div">
                    <div class="business_title_img">
                        <!-- <img :src="imgSrc[1]"/> -->
                        <p>主营业务</p>
                        <div class="line_div_box"></div>
                    </div>
                </div>
                <div class="main_tab_content">
                    <div class="business_con_div" v-show="showLeftFlag">
                        <div class="business_text_div">
                            <div class="business_text_all_box">
                                <div class="main_title">
                                    <span>{{contentObj.title}}</span>
                                    <!-- <div class="white_line_div_one"></div> -->
                                </div>
                                <div class="main_section_all_box">
                                    <p v-for="c in contentObj.contentText" :key="c.id">{{c.texts}}</p>
                                </div>
                                <div class="bottom_two_module">
                                    <div class="left_module_box">
                                        <div class="white_line_div_two"></div>
                                        <p class="left_title">{{contentObj.bottom_content_title}}</p>
                                        <div class="download_img_icon_box" v-if="[5,6].indexOf(curActive)!==-1">
                                            <div class="download_img_icon">
                                                <img :src="imgSrc[3]"/>
                                            </div>
                                            <span @click="downloadFun(curActive)">案例PDF介绍</span>
                                        </div>
                                    </div>
                                    <div class="right_module_box">
                                        <p  class="right_introduce" v-for="ul in contentObj.div_ul_obj" :key="ul.id">
                                            <b class="circle_b"></b>
                                            <span>{{ul.value}}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="business_img_div">
                            <img :src="contentObj.right_img"/>
                        </div>
                    </div>
                    <div class="business_con_div" v-show="showRightFlag">
                        <div class="business_img_div">
                            <img :src="contentObj.right_img"/>
                        </div>
                        <div class="business_text_div">
                            <div class="business_text_all_box">
                                <div class="main_title">
                                    <span>{{contentObj.title}}</span>
                                    <!-- <div class="white_line_div_one"></div> -->
                                </div>
                                <div class="main_section_all_box">
                                    <p v-for="c in contentObj.contentText" :key="c.id">{{c.texts}}</p>
                                </div>
                                <div class="bottom_two_module">
                                    <div class="left_module_box">
                                        <div class="white_line_div_two"></div>
                                        <p class="left_title">{{contentObj.bottom_content_title}}</p>
                                        <div class="download_img_icon_box" v-if="[5,6].indexOf(curActive)!==-1">
                                            <div class="download_img_icon">
                                                <img :src="imgSrc[3]"/>
                                            </div>
                                            <span @click="downloadFun(curActive)">案例PDF介绍</span>
                                        </div>
                                    </div>
                                    <div class="right_module_box">
                                        <p  class="right_introduce" v-for="ul in contentObj.div_ul_obj" :key="ul.id">
                                            <b class="circle_b"></b>
                                            <span>{{ul.value}}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import utils from '@/libs/utils';
export default {
    components: {},
    computed: {},
    mounted() {
        this.params();
    },
    watch: {
        '$route' (to, from) {
            if(to.query.tabActive != from.query.tabActive){
                this.curActive = Number(to.query.tabActive);
                this.tabClickFun(this.curActive);
            }
        }
    },
    methods: {
        params(){
            var query = location.search.substr(1);
            query = query.split('&')
            var params = {};
            for (let i = 0; i < query.length; i++) {
                let q = query[i].split('=')
                if (q.length === 2) {
                    params[q[0]] = q[1]
                }
            }
            if((JSON.stringify(params) === '{}')===false){ //判断是否为空对象
                this.curActive = Number(params.tabActive);
            }else{
                this.curActive = 1;
            }
            this.tabClickFun(this.curActive);
        },
        tabClickFun(id){
            this.curActive = id;
            this.contentAll.forEach((item)=>{
                if(id === item.key){
                   this.contentObj = item;
                }
            })
            if([1,3,5].indexOf(id)!==-1){
                this.showLeftFlag = true;
                this.showRightFlag = false;
            }else{
                this.showRightFlag = true;
                this.showLeftFlag = false;
            }
        },
        downloadFun(id){
            // utils.downloadFile('https://wetec-constrain.cdn.bcebos.com/material/dzhi/ppt2021.pptx','项目物流')
            id === 5 ? window.open('https://wetec-constrain.cdn.bcebos.com/material/dzhi/ceiv.pdf','_blank') : window.open('https://wetec-constrain.cdn.bcebos.com/material/dzhi/%E9%A1%B9%E7%9B%AE%E7%89%A9%E6%B5%81.pdf','_blank')
        }
    },
    data() {
        var imgSrc = [];
        imgSrc.push(require('../../assets/img/business/business_banner.png'));//(0)
        imgSrc.push(require('../../assets/img/business/hy_business.png'));//(1)
        imgSrc.push(require('../../assets/img/business/download.png'));//(2)
        imgSrc.push(require('../../assets/img/business/look_icon.png'));//(3)
        return {
            imgSrc: imgSrc,
            curActive: 1,
            tabArr:[
                {id: 1,name: '海运'},
                {id: 2,name: '空运'},
                {id: 3,name: '合约物流'},
                {id: 4,name: '中欧铁路'},
                {id: 5,name: '医药物流'},
                {id: 6,name: '项目物流'},
            ],
            showLeftFlag:false,
            showRightFlag:false,
            contentObj:{},
            contentAll:[
                {
                    key:1,
                    title: '海运',
                    contentText:[
                        {id: 1,texts:'凭借20多年的NVOCC货代经验，我们与多个知名海运承运商共同建立了强大运输网络，每年可为客户运送100,000TEU货物至全球各大角落。'},
                        {id: 2, texts: '澳新航线的服务是我司的核心业务，凭借着多年的经验，我们已在该方面业务取得较大成就。近年来我们陆续拓展美线，欧洲线以及东南亚航线服务。'},
                        {id: 3,texts: '我们可为您提供灵活的海运解决方案，并配套先进的货运追踪系统，确保您的货物能够安全、及时的抵达目的地。'}
                    ],
                    bottom_content_title: '主要服务',
                    div_ul_obj: [
                        {id: 1, value: '订舱'},
                        {id: 2, value: '整柜/拼箱服务'},
                        {id: 3, value: '危险品货物'},
                        {id: 4, value: '买家集运'},
                        {id: 5, value: '租箱&包船服务'},
                        {id: 6, value: '报关服务'},
                        {id: 7, value: '货运追踪系统–Cargowise'},
                        {id: 8, value: '项目物流'},
                    ],
                    right_img: require('../../assets/img/business/hy1_business.png')
                },
                {
                    key:2,
                    title: '空运',
                    contentText:[
                        {id: 1,texts:'我司与世界各大知名航空公司维持良好的长期合作关系，可为您定制各类空运解决方案。我们在上海，香港，深圳，广州，厦门，福州，北京等中国各大主要站点都可为您提供空运进出口服务。在疫情期间，我们凭借着强大的服务网络，及时、安全的将各行业客户的货物运抵目的地。作为 CEIV Pharma认证的服务商，我们也可为您提供专业的医药物流运输服务。'},
                    ],
                    bottom_content_title: '主要服务',
                    div_ul_obj: [
                        {id: 1, value: '年度包板协议'},
                        {id: 2, value: '危险品货物'},
                        {id: 3, value: '医药和温控货物'},
                        {id: 4, value: '电商物流'},
                        {id: 5, value: '手提货服务'},
                        {id: 6, value: '全天24小时货物追踪'},
                        {id: 7, value: '包机服务'},
                    ],
                    right_img: require('../../assets/img/business/ky_business.png')
                },
                {
                    key:3,
                    title: '合约物流',
                    contentText:[
                        {id: 1,texts:'我们可为您提供整合仓储，分销配送管理以及高效专业的供应链解决方案。帮助解决大批量订单导致目的地高库存量，不必要运输成本增加等一系列的问题。'},
                        {id: 2, texts:'2018年，我司在上海洋山特殊自贸区开设了10,000平方米的保税仓库，系统自动化管理库存、进出仓分拣、包装、装箱等环节，为客户提供可视化数据支持，定制化配货方案以及成本最优方案。'}
                    ],
                    bottom_content_title: '主要服务',
                    div_ul_obj: [
                        {id: 1, value: '跨国/买家集运服务'},
                        {id: 2, value: '增值服务 ( 拣货，贴标，包装，组装等)'},
                        {id: 3, value: '供应商管理'},
                        {id: 4, value: '系统对接'},
                        {id: 5, value: '干线运输'},
                        {id: 6, value: '保税报关，转关服务'},
                    ],
                    right_img: require('../../assets/img/business/htwl1_business.png')
                },
                {
                    key:4,
                    title: '中欧铁路',
                    contentText:[
                        {id: 1,texts:'跟随国家一带一路政策，我们可为您提供多条线路，定制化的国际铁路进出口运输解决方案。'},
                        {id: 2, texts:'2021年，我司专门成立全资子公司SGIT，为不同行业客户通过铁路服务运送达1,600TEU集装箱。'}
                    ],
                    bottom_content_title: '主要路线',
                    div_ul_obj: [
                        {id: 1, value: '中欧班列'},
                        {id: 2, value: '中俄班列'},
                        {id: 3, value: '中亚班列'},
                        {id: 4, value: '中越班列'},
                        {id: 5, value: '中老班列'},
                    ],
                    right_img: require('../../assets/img/business/zotl1_business.png')
                },
                {
                    key:5,
                    title: '医药物流',
                    contentText:[
                        {id: 1,texts:'自全球疫情泛滥，各国政府机构、慈善组织运输防疫物品需求急剧增加。2022年3月，经过一系列严格的审核，我司荣获IATA 全球权威药品物流运输资质认证-CEIV Pharma认证证书。 我们已按照国际标准，对冷链药品管理运输流程进行了全面细化和规范，并将在医药货物运输的各个环节进行严格的质量把控。可根据不同客户需求，定制个性化化医药运输解决方案，助您的货物及时、安全的送达。'},
                    ],
                    bottom_content_title: '主要服务',
                    div_ul_obj: [
                        {id: 1, value: 'GDP标准温控仓库'},
                        {id: 2, value: '温控车辆运输服务'},
                        {id: 3, value: '空运包机服务'},
                        {id: 4, value: '干冰，蓝冰和温控箱等设备'},
                    ],
                    right_img: require('../../assets/img/business/yywl1_business.png')
                },
                {
                    key:6,
                    title: '项目物流',
                    contentText:[
                        {id: 1,texts:'东志集团在散杂货物，滚装船业务以及综合项目物流方面拥有丰富经验。'},
                        {id: 2, texts:'在过去几年，我们运输过各类货物，例如钢铁产品、登机桥、游艇、船坞组件和变压器等散杂货及超大型工业项目货物。'}
                    ],
                    bottom_content_title: '主要服务',
                    div_ul_obj: [
                        {id: 1, value: '可行性方案'},
                        {id: 2, value: '在起运港和目的港现场勘验'},
                        {id: 3, value: '多式联运服务，包括空运、海运、陆运和铁路运输服务'},
                        {id: 4, value: '散装、滚装、重载、特种箱、特殊设备（平架、敞顶、平台集装箱）和船舶租赁服务'},
                        {id: 5, value: '空气悬架 / 温控卡车运输和白手套服务'},
                    ],
                    right_img: require('../../assets/img/business/xmwl_business.png')
                },
            ],
        }
    }
}
</script>
<style lang="less">
.main_business_div{
    .business_banner{
        width:100%;
        img{
            width:100%;
            height:100%;
        }
    }
    .business_title_div{
        //tab页
        .tab_div{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 107px;
            margin-top: -60px;
            .tab-title{
                display: flex;
                align-items: center;
                height: 107px;
                background: #fff;
                box-shadow:  0px 1px 17px 1px rgba(0,0,0,0.1000);
                li{
                    text-align: center;
                    margin: 0 50px;
                    cursor: pointer;
                    font-size: 20px;
                    color: #232931;
                    position: relative;
                }
                li:hover::after{
                    width: 100%;
                }
                li::after{
                    transition: all 0.5s;
                    position: absolute;
                    content: "";
                    bottom: 0px;
                    left: 0px;
                    width: 0px;
                    height: 3px;
                    background: #2200ba;
                    // top: 0px;
                }
            }
            .tab-title .active {
                font-weight: 700;
                &::after{
                    width: 100%;
                }
			}
        }
        //标题
        .business_title_div{
            display: flex;
            justify-content: center;
            .business_title_img{
                margin: 50px 0 30px 0;
                font-size: 30px;
                font-weight: 700;
                .line_div_box{
                    background: #2200ba;
                    width: 30px;
                    height: 4px;
                    margin: auto;
                }
                img{
                    width:100%;
                    height:100%;
                }
            }
        }
        //tab页内容
        .main_tab_content{
            background: #2C2E32;
            margin: 40px 0 60px 0;
            .business_con_div{
                display: flex;
                // justify-content: space-between;
                .business_text_div{
                    display: flex;
                    justify-content: center;
                    padding: 50px 0;
                    .business_text_all_box{
                        width: 90%;
                        .main_title{
                            font-size: 44px;
                            font-weight: bold;
                            color: #FFFFFF;
                            // text-align: center;
                            .white_line_div_one{ //公共白条
                                width:60px;
                                height: 3px;
                                background: #fff;
                                display: inline-block;
                                margin:0 0 0 15px;
                            }
                        }
                        .main_section_all_box{
                            margin: 30px 0;
                            p{
                                font-size: 14px;
                                color: #FFFFFF;
                                line-height: 40px;
                            }
                        }
                        .bottom_two_module{
                            color: #FFFFFF;
                            display: flex;
                            // justify-content: space-between;
                            // padding-right: 50px;
                            .left_module_box{
                                line-height:50px;
                                padding-right: 50px;
                                .white_line_div_two{
                                    width:45px;
                                    height: 3px;
                                    background: #fff;
                                }
                                .left_title{
                                    font-size: 20px;
                                }
                                .download_img_icon_box{
                                    display: flex;
                                    align-items: center;
                                    .download_img_icon{
                                        width: 28px;
                                        height: 28px;
                                        margin-right: 5px;
                                        img{
                                            width:100%;
                                            height:100%;
                                        }
                                    }
                                    span{
                                        cursor: pointer;
                                    }
                                }
                            }
                            .right_module_box{
                                width: 65%;
                                .right_introduce{
                                    line-height: 30px;
                                    display: flex;
                                    // align-items:center;
                                    .circle_b{
                                        width: 6px;
                                        height: 6px;
                                        background: #fff;
                                        display: inline-block;
                                        border-radius: 3px;
                                        // margin-right: 8px;
                                        flex-shrink: 0;
                                        margin: 11px 10px 0 0;
                                    }
                                }
                            }
                        }
                    }
                }
                .business_img_div{
                    // width:629px;
                    // height:766px;
                    img{
                        font-size: 0;
                        display: block;
                        width:500px;
                        height:100%;
                        // width:100%;
                        // height:100%;
                    }
                }
            }
        }
    }
}
@media screen and (max-width:900px){
    .main_business_div{
        .business_title_div{
            //tab页
            .tab_div{
                margin-top: 0;
                height: 70px;
                .tab-title{
                    height: 70px;
                    li{
                        margin: 0 20px;
                        font-size: 12px;
                    }
                }
            }
            //tab页内容
            .main_tab_content{
                .business_con_div{
                    flex-direction: column;
                    .business_text_div{
                        .bottom_two_module{
                            flex-direction: column;
                            .right_module_box{
                                width:100%!important;
                            }
                        }
                    }
                    .business_img_div{
                        width:100%;
                        height:100%;
                        img{
                           width: 100%;
                           height: 100%;
                        }
                    }
                }
            }

        }
    }
}
</style>
